<template>
  <div id="order-create-success-modal" class="modal">
    <div class="modal__content modal__content--xl">
      <div class="p-5 text-center">
        <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
        <div class="text-3xl mt-5">Lên {{ ids.length }} đơn thành công</div>
        <div class="text-gray-600 mt-5">
          Vui lòng đợi nhân viên giao nhận đến lấy hàng
        </div>
      </div>
      <div class="px-5 pb-8 flex items-center justify-center">
        <button
          type="button"
          @click="printOrder('J_T')"
          class="button w-48 bg-theme-1 text-white mr-2 inline-block flex justify-center"
        >
          <LoadingIcon
            color="white"
            icon="spinning-circles"
            class="w-4 h-4 mr-2"
            v-if="loadingPrint"
          />
          In theo mẫu đơn JT
        </button>
        <button
          type="button"
          @click="printOrder('80x80')"
          class="button w-32 bg-theme-2 dark:bg-dark-1 mr-2 flex inline-block justify-center"
        >
          <LoadingIcon
            color="grey"
            icon="spinning-circles"
            class="w-4 h-4 mr-2"
            v-if="loadingPrint"
          />
          80x80
        </button>
        <button
          type="button"
          @click="printOrder('A5_Old')"
          class="button w-40 bg-theme-2 dark:bg-dark-1 flex inline-block justify-center"
        >
          <LoadingIcon
            color="grey"
            icon="spinning-circles"
            class="w-4 h-4 mr-2"
            v-if="loadingPrint"
          />
          Giấy A5 (cũ)
        </button>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          data-dismiss="modal"
          class="button w-56 bg-theme-4 text-white mr-2"
        >
          Tiếp tục tạo đơn hàng
        </button>
        <button
          type="button"
          data-dismiss="modal"
          @click="goToOrderQuery"
          class="button w-40 bg-theme-2 dark:bg-dark-1"
        >
          Chi tiết đơn hàng
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { showError } from "@/libs/notification";
import { orderService } from "@/services/order";
import { defineComponent, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "OrderCreateSuccessModal",
  props: {
    ids: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const loadingPrint = ref(false);
    const { ids } = toRefs(props);

    const router = useRouter();

    const printOrder = async (
      template: "J_T" | "A4" | "A5" | "A5_Old" | "80x80"
    ) => {
      try {
        loadingPrint.value = true;
        const data = await orderService.print(ids.value as string[], template, "excel_index");
        if (data?.result?.printUrl) {
          window.open(data?.result?.printUrl, "_blank");
        }
      } catch (error) {
        showError(error);
      } finally {
        loadingPrint.value = false;
      }
    };

    const goToOrderQuery = () => {
      router.push({ name: "order-query" });
    }

    return {
      loadingPrint,
      printOrder,
      goToOrderQuery,
    };
  },
});
</script>
