
import { showError } from "@/libs/notification";
import { orderService } from "@/services/order";
import { defineComponent, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "OrderCreateSuccessModal",
  props: {
    ids: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const loadingPrint = ref(false);
    const { ids } = toRefs(props);

    const router = useRouter();

    const printOrder = async (
      template: "J_T" | "A4" | "A5" | "A5_Old" | "80x80"
    ) => {
      try {
        loadingPrint.value = true;
        const data = await orderService.print(ids.value as string[], template, "excel_index");
        if (data?.result?.printUrl) {
          window.open(data?.result?.printUrl, "_blank");
        }
      } catch (error) {
        showError(error);
      } finally {
        loadingPrint.value = false;
      }
    };

    const goToOrderQuery = () => {
      router.push({ name: "order-query" });
    }

    return {
      loadingPrint,
      printOrder,
      goToOrderQuery,
    };
  },
});
